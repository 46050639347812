<template>
  <div>
    <div class="search">
      <el-input placeholder="Search" v-model="search" clearable></el-input>
    </div>
    <el-table v-loading="tableLoading" :data="referralData" height="50vh" >
      <el-table-column label="Referrer Name">
        <template slot-scope="scope">
          {{ scope.row.referrer.name }}
        </template>
      </el-table-column>
      <el-table-column label="Referer Phone">
        <template slot-scope="scope">
          {{ scope.row.referrer.phone }}
        </template>
      </el-table-column>
      <el-table-column
        label="Total Amount"
        prop="total_amount"
      ></el-table-column>
      <el-table-column
        label="Total Redeemed"
        prop="total_redeemed"
      ></el-table-column>
      <el-table-column
        label="Not Redeemed"
        prop="total_unredeemed"
      ></el-table-column>
      <el-table-column label="Action">
        <template slot-scope="scope">
          <div class="action-container">
            <el-button type="success" size="small" @click="redeem(scope.row)"
              :disabled="scope.row.total_unredeemed == 0">Redeem</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="viewDetails(scope.row)"
              >View Details</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  export default {
    data() {
      return {
        tableLoading: false,
        search: "",
      };
    },
    computed: {
      referralData() {
        return store.getters["admin/referrals/getReferralData"].data.filter(
          (data) =>
            data.referrer.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.referrer.phone == this.search
        );
      },
    },
    methods: {
      redeem(data) {
        this.tableLoading = true;
        store
          .dispatch("admin/referrals/redeemReferral", {
            referrer_id: data.referrer.id,
          })
          .then((data) => {
            this.$message.success("Referral redeemed successfully");
            this.refreshData();
          })
          .catch(() => {
            this.$message.error("Error redeeming referral");
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
      viewDetails(user) {
        this.$router
          .push(`/admin/referrals/user/${user.referrer.id}`)
          .catch(() => {});
      },
      refreshData() {
        this.tableLoading = true;
        store
          .dispatch("admin/referrals/getReferrals")
          .then((data) => {})
          .catch(() => {
            this.$message.error("Error fetching user referrals");
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
    },
    mounted() {
      this.refreshData();
    },
  };
</script>
<style scoped>
  .action-container {
    display: flex;
  }

  .search {
    float: right;
    width: 18rem;
    margin-bottom: 20px;
  }
</style>
