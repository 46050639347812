<template>
  <div>
    <div class="search">
      <el-input placeholder="Search" v-model="search" clearable></el-input>
    </div>
    <el-table :data="referralSettings" v-loading="tableLoading">
      <el-table-column label="Type" prop="type"> </el-table-column>
      <el-table-column label="Amount" prop="amount"> </el-table-column>

      <el-table-column label="Action">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="danger"
            @click="editReferralType(scope.row)"
            >Edit Referral Type</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="editReferralModal" title="Edit Referral Type">
      <div class="edit-bundle-type">
        <el-form
          :model="referralDetails"
          label-width="120px"
          ref="form"
          :rules="rules"
          label-position="top"
        >
          <el-form-item label="Type" prop="type">
            <el-input v-model="referralDetails.type"></el-input>
          </el-form-item>
          <el-form-item label="Amount" prop="amount">
            <el-input v-model="referralDetails.amount"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editReferralModal = false">Cancel</el-button>
        <el-button
          type="danger"
          @click="confirmUpdate"
          :loading="updateReferralLoading"
          >Update</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import Storage from "@/services/storage.js";

  let storage = new Storage();

  export default {
    data() {
      return {
        editReferralModal: false,
        tableLoading: false,
        updateReferralLoading: false,
        referralDetails: {
          type: "",
          amount: "",
        },
        rules: {
          type: [
            { required: true, message: "Enter referral type", trigger: "blur" },
          ],
          amount: [
            {
              required: true,
              message: "Enter referral amount",
              trigger: "blur",
            },
          ],
        },
        search: "",
      };
    },
    computed: {
      referralSettings() {
        return store.getters["admin/referrals/getReferralSettings"].filter(
          (item) => {
            return item.type.toLowerCase().includes(this.search.toLowerCase());
          }
        );
      },
    },
    mounted() {
      this.tableLoading = true;
      store
        .dispatch("admin/referrals/getReferralSettings")
        .then()
        .catch(() => {
          this.$message.error("Error fetching referral settings");
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    methods: {
      editReferralType(scope) {
        this.referralDetails = {
          type: scope.type,
          amount: scope.amount,
          user_id: storage.getSavedState("auth.admin").user_id,
        };
        this.editReferralModal = true;
      },
      confirmUpdate() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.updateReferralLoading = true;
            store
              .dispatch(
                "admin/referrals/updateReferralSettings",
                this.referralDetails
              )
              .then((data) => {
                this.$message({
                  type: "success",
                  message: "Referral type updated successfully",
                });
                this.editReferralModal = false;
                this.refreshData();
              })
              .finally(() => {
                this.updateReferralLoading = false;
              });
          } else {
            this.updateReferralLoading = false;
            return false;
          }
        });
      },
      refreshData() {
        this.tableLoading = true;
        store
          .dispatch("admin/referrals/getReferralSettings")
          .then()
          .catch(() => {
            this.$message.error("Error fetching referral settings");
          })
          .finally(() => {
            this.tableLoading = false;
          });
      },
    },
  };
</script>
<style scoped>
  .search {
    float: right;
    width: 18rem;
    margin-bottom: 20px;
  }
  .edit-bundle-type >>> .el-form-item__label {
    margin-bottom: 0;
  }
</style>
